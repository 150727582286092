import { Box, Text, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import ModalLayout from "../Layout/ModalLayout";
import { adminDeclineLoanDisbursement } from "../../services/axios";
import { toast } from "react-toastify";

const DeclineDisbursementModal = ({ loanRequest, isOpen, setIsOpen }) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const declineDisbursementHandler = async () => {
    if (!reason) {
      return toast.error(
        "Please enter a reason for declining this disbursement"
      );
    }
    const payload = {
      reason: reason,
    };
    setLoading(true);
    const res = await adminDeclineLoanDisbursement(
      loanRequest.request_id,
      payload
    );
    setLoading(false);

    if (res) {
      toast.success("Loan Disbursement Declined successfully");
      setTimeout(() => {
        setIsOpen(false);
        window.location.reload();
      }, 2000);
    }
  };

  return (
    <>
      <ModalLayout
        successBtnText={loading ? "PLEASE WAIT..." : "DECLINE DISBURSEMENT"}
        cancelBtnText="CANCEL"
        title="Decline Loan Disbursement"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={false}
        rejectHandler={declineDisbursementHandler}
      >
        <Box marginY="5">
          <Text color="#540A45" fontWeight="500" fontSize="15px">
            Why are you declining this disbursement
          </Text>

          <Textarea
            value={reason}
            rows={10}
            mt="10px"
            placeholder="Enter reason for declining disbursement. This will be helpful for the customer support team to understand why this disbursement was declined"
            onChange={(e) => setReason(e.currentTarget.value)}
            className="form-input"
          />
        </Box>
      </ModalLayout>
    </>
  );
};

export default DeclineDisbursementModal;
