import { Box, Text } from "@chakra-ui/react";
import { useState } from "react";
import ModalLayout from "../Layout/ModalLayout";
import { adminRejectInstantLoan } from "../../services/axios";
import { toast } from "react-toastify";

const RejectInstantLoan = ({ loanRequest, isOpen, setIsOpen, setEditLoan }) => {
  const [loading, setLoading] = useState(false);
  const rejectLoanHandler = async () => {
    setLoading(true);
    try {
      await adminRejectInstantLoan(loanRequest.request_id, loanRequest.amount);
      setIsOpen(false);
      setLoading(false);
      setEditLoan(3);
      toast.success("Loan request rejected successfully");
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalLayout
        successBtnText={loading ? "PLEASE WAIT..." : "REJECT"}
        cancelBtnText="CANCEL"
        title="Reject Loan Request"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={false}
        rejectHandler={rejectLoanHandler}
      >
        <Box marginY="5">
          <Text color="#540A45" fontWeight="500" fontSize="15px">
            Are you sure you want to reject this loan request?
          </Text>
        </Box>
      </ModalLayout>
    </>
  );
};

export default RejectInstantLoan;
