import React from "react";
import {
  Text,
  InputGroup,
  InputLeftElement,
  Input,
  Flex,
  Button,
} from "@chakra-ui/react";
import { BiChevronDown, BiSearch } from "react-icons/bi";

function HervestSearch({ searchHandler }) {
  return (
    <Flex
      spac
      columns={[2, 2, 4, 4]}
      justify="space-between"
      mt="10px"
      p={["25px 10px", "25px 15px", "25px"]}
      bg="#FFFFFF"
      maxWidth="100%"
    >
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <BiSearch size="20px" color="#666666" />
        </InputLeftElement>
        <Input
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              searchHandler(e);
            }
          }}
          border="1px solid #E4E8F2"
          fontSize="13px"
          fontFamily="satoshiBold"
          color="#666666"
          type="tel"
          placeholder="Search name or enter keyword"
        />
      </InputGroup>

      <Flex
        border="1px solid #5B2E4F"
        bg="#FFFFFF"
        borderRadius="7px"
        marginX="4"
      >
        <Flex p="0 20px" justify="center" align="center">
          <Text
            fontSize="13px"
            color="#5B2E4F"
            fontWeight="500"
            fontFamily="satoshiBold"
            align="center"
          >
            All
          </Text>
        </Flex>
        <Flex
          p="0 10px"
          borderLeft="1px solid #5B2E4F"
          justify="center"
          align="center"
        >
          <BiChevronDown />
        </Flex>
      </Flex>
      <Flex border="1px solid #5B2E4F" bg="#FFFFFF" borderRadius="7px">
        <Flex p="0 15px" justify="center" align="center">
          <Text
            fontSize="13px"
            color="#5B2E4F"
            fontWeight="500"
            fontFamily="satoshiBold"
            align="center"
          >
            7days
          </Text>
        </Flex>
        <Flex
          p="0 10px"
          borderLeft="1px solid #5B2E4F"
          justify="center"
          align="center"
        >
          <BiChevronDown />
        </Flex>
      </Flex>
      <Button
        onClick={() => {
          //   handleExportRows(instantLoans);
        }}
        bg="#5B2E4F"
        color="white"
        fontSize="12px"
        fontWeight="500"
        p="0 30px"
        marginX="4"
      >
        EXPORT
      </Button>
    </Flex>
  );
}

export default HervestSearch;
