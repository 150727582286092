import { toast } from "react-toastify";
export const ErrorHandler = (error) => {
  const message =
    (error?.response &&
      error?.response?.data &&
      error?.response?.data?.message) ||
    error?.message ||
    error?.toString();
  toast.error(`${message}`);
};

export function formatMoney(amount) {
  if (!amount) return 0;
  const parts = amount?.toString().split(".");
  const integerPart = parts[0];
  const decimalPart = parts[1] || "";
  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const formattedMoney =
    integerWithCommas + (decimalPart ? "." + decimalPart : "");
  return formattedMoney;
}

export function objectToFormData(obj) {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      formData.append(key, value);
    }
  });

  return formData;
}

export function obscureText(text, visibleStart = 3, visibleEnd = 3) {
  if (!text) {
    return;
  }
  if (text.length <= visibleStart + visibleEnd) {
    return text; // If text is too short to obscure, return it as is
  }

  const start = text.slice(0, visibleStart);
  const end = text.slice(-visibleEnd);
  const obscuredMiddle = "*".repeat(text.length - visibleStart - visibleEnd);

  return `${start}${obscuredMiddle}${end}`;
}
