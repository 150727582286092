import React, { useState, useEffect } from "react";
import LoginWithCode from "../components/auth/LoginWithCode";
import LoginWithPassword from "../components/auth/LoginWithPassword";
import ValidateAuthenticator from "../components/auth/ValidateAuthenticator";

const Signin = () => {
  const [loginOption, setLoginOption] = useState("password");
  const [validateDetails, setValidateDetails] = useState({});

  useEffect(() => {
    const name = localStorage.getItem("name");
    if (name) {
      setLoginOption("validate");
    }
  }, []);

  return (
    <div className="loginBg">
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        {loginOption === "password" && (
          <LoginWithPassword
            setLoginOption={setLoginOption}
            setValidateDetails={setValidateDetails}
          />
        )}
        {loginOption === "validate" && (
          <LoginWithCode setLoginOption={setLoginOption} />
        )}
        {loginOption === "setup" && (
          <ValidateAuthenticator validateDetails={validateDetails} />
        )}
      </div>
    </div>
  );
};

export default Signin;
