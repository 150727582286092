import axios from "axios";

import { API_BASE_URL } from "./constants/apiConstants";
import { ErrorHandler } from "./utils/helpersFunc";

const authAdminPasskeyLogin = async (payload) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL}/account/login-admin-passkey`,
      payload
    );
    return data.data;
  } catch (err) {
    ErrorHandler(err);
  }
};

const verifyAuthAdminPasskeyLogin = async (payload) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL}/account/verify-login-passkey`,
      payload
    );
    return data.data;
  } catch (err) {
    ErrorHandler(err);
  }
};

const authAdminPasskeyRegister = async (payload) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL}/account/authenticate-admin-passkey`,
      payload
    );
    return data.data;
  } catch (err) {
    ErrorHandler(err);
  }
};

const authAdminPasskeyVerifyPasskeyRegister = async (payload) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL}/account/verify-admin-passkey`,
      payload
    );
    return data.data;
  } catch (err) {
    ErrorHandler(err);
  }
};

const setAdminPassword = async (payload) => {
  try {
    const res = await axios.post(
      `${API_BASE_URL}/admin/create-password`,
      payload
    );
    return res;
  } catch (err) {
    ErrorHandler(err);
  }
};

const loginWith2FA = async (payload) => {
  try {
    const res = await axios.post(
      `${API_BASE_URL}/account/twofa-admin-login`,
      payload
    );
    return res?.data?.data;
  } catch (err) {
    ErrorHandler(err);
  }
};

const authServices = {
  setAdminPassword,
  authAdminPasskeyRegister,
  authAdminPasskeyVerifyPasskeyRegister,
  authAdminPasskeyLogin,
  verifyAuthAdminPasskeyLogin,
  loginWith2FA,
};

export default authServices;
