import React from "react";
import { VStack, Box, Flex, Text, Image } from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
//import logo from "../assets/"
const MenuLinks = () => {
  // const styles = {
  //     className: "color = red",
  //     activeClassName: "color =green"
  // }
  const role = localStorage.getItem("role");
  const navigate = useNavigate();

  const menuLinks = [
    {
      id: 1,
      title: "Main Menu",
      getPages: () => {
        const pages = [
          {
            id: 1,
            title: "Dashboard",
            path: "dashboard",
            activeIcon: "active-dashboard.svg",
            unactiveIcon: "dashboard-unactive.svg",
          },
          {
            id: 2,
            title: "Instant Loan",
            path: "instant-loan",
            activeIcon: "instant-inactive.svg",
            unactiveIcon: "instant-active.svg",
          },
          {
            id: 8,
            title: "Instant Loan Transactions",
            path: "transactions",
            activeIcon: "instant-inactive.svg",
            unactiveIcon: "instant-active.svg",
          },
        ];

        if (role === "Reporting") {
          pages.push({
            id: 5,
            title: "Rejected Loans",
            path: "rejected",
            activeIcon: "reject-icon.svg",
            unactiveIcon: "reject-icon.svg",
          });
        }

        if (role === "Admin") {
          // Only add the "Approvals" object if the role is "Admin"
          pages.push({
            id: 3,
            title: "Approvals",
            path: "approvals",
            activeIcon: "active-approval.svg",
            unactiveIcon: "approval-unactive.svg",
          });
        }

        if (role === "Finance Admin") {
          pages.push(
            {
              id: 2,
              title: "Pending Disbursement",
              path: "pending-disbursement",
              activeIcon: "instant-inactive.svg",
              unactiveIcon: "instant-active.svg",
            },
            {
              id: 3,
              title: "Direct Debit Mandates",
              path: "dashboard/direct-debits",
              activeIcon: "active-approval.svg",
              unactiveIcon: "approval-unactive.svg",
            }
          );
        }

        return pages;
      },
    },
  ];

  const handleLogout = (page) => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    navigate("/");
  };
  return (
    <Box>
      <VStack>
        {menuLinks?.map((menuLink, i) => (
          <Box w="full" m="10px" key={menuLink?.id}>
            <VStack align="none" w="100%" p="0 10px " spacing="2">
              {menuLink?.getPages()?.map((page) => {
                return (
                  <NavLink
                    to={page?.path}
                    key={page?.id}
                    style={({ isActive }) => ({
                      background: isActive ? "#F8F1F6" : "none",
                      borderRadius: isActive ? "10px" : 0,
                      transition: "all .5s linear",
                    })}
                  >
                    <Flex p="10px" transition="all .5s linear" w="100%">
                      <Image
                        w="4"
                        src={require("../../assets/" + page?.activeIcon)}
                        objectFit="contain"
                      />
                      <Text
                        fontSize="12px"
                        ml="4"
                        fontWeight="700"
                        color="#5B2E4F"
                        letterSpacing="0.25px"
                      >
                        {page?.title}
                      </Text>
                    </Flex>
                  </NavLink>
                );
              })}
            </VStack>
            <Flex
              mt="200px"
              p="10px"
              transition="all .5s linear"
              w="100%"
              onClick={handleLogout}
              cursor="pointer"
              color="red"
              ml="10px"
            >
              <BiLogOut />
              <Text
                fontSize="12px"
                ml="4"
                fontWeight="700"
                color="red"
                letterSpacing="0.25px"
              >
                Logout
              </Text>
            </Flex>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default MenuLinks;
