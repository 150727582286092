import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Text,
  Button,
  VStack,
  Input,
} from "@chakra-ui/react";
import { BiChevronLeft } from "react-icons/bi";
import NokDisplay from "../components/RequestDocument/NokDisplay";
import Documents from "../components/RequestDocument/Documents";
import FinanceApproval from "../components/RequestDocument/FinanceApproval";
import ApprovalWorkFlow from "../components/RequestDocument/ApprovalWorkFlow";
import {
  commentLogs,
  getALoanRequest,
  getLoanRequestSLA,
  sendDirectDebitMandate,
} from "../services/axios";
import GuarantorInformation from "../components/RequestDocument/GuarantorInformation";
import { Link, useParams } from "react-router-dom";
import ApproveLoanModal from "../components/Modals/ApproveLoanModal";
import RejectionModal from "../components/Modals/RejectionModal";
import BorrowersInformation from "../components/RequestDocument/BorrowersInformation";
import CreditApprovalModal from "../components/Modals/CreditApprovalModal";
import FinanceApproveLoanModal from "../components/Modals/FinanceApproveLoanModal";
import BorrowerSignature from "../components/RequestDocument/BorrowerSignature";
import LogLoanModal from "../components/RequestDocument/LogLoanModal";
import Loader from "../components/Loader/loader";
import RequestSLA from "../components/RequestSLA";
import RepaymentSchedule from "../components/RequestDocument/RepaymentSchedule";
import TransactionLog from "../components/RequestDocument/TransactionLog";
import InstantLoanOfferDetails from "../components/RequestDocument/LoanOfferDetails";
import { toast } from "react-toastify";
import ModalLayout from "../components/Layout/ModalLayout";
import RepaymentConfig from "../components/RequestDocument/RepaymentConfig";

const RequestPage = () => {
  const { id } = useParams();

  const isRejectedLoans = sessionStorage.getItem("rejected");
  const [formValues, setFormValues] = useState({
    monthly_repayment: "",
  });

  const role = localStorage.getItem("role");

  const [loanRequest, setLoanRequest] = useState([]);
  const [loanLogs, setLoanLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [processing, setProcessing] = useState(false);
  const [loanRequestSLA, setLoanRequestSLA] = useState();
  const [processingMandate, setProcessingMandate] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const getLogs = async (theLimit) => {
    setProcessing(true);
    const { data } = await commentLogs(id, theLimit);
    if (data) {
      setLoanLogs(data?.data);
    }
    setProcessing(false);
    return;
  };

  const loadLoanRequestSLA = async () => {
    const { data } = await getLoanRequestSLA(id);
    setLoanRequestSLA(data);
  };

  const sendMandateHandler = async () => {
    if (!formValues.monthly_repayment) {
      return toast.error("Please enter the monthly repayment amount");
    }

    const payload = {
      monthly_repayment: Number(formValues.monthly_repayment),
      requestId: id,
    };

    setProcessingMandate(true);
    const res = await sendDirectDebitMandate(payload);
    setProcessingMandate(false);
    if (res) {
      toast.success("Direct Debit Mandate sent successfully");
      setIsOpen(false);
      setFormValues({
        monthly_repayment: "",
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const { data } = await getALoanRequest(id);
      setLoanRequest(data);
      setLoading(false);
    };
    getData();
    getLogs(limit);
    loadLoanRequestSLA();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {loading && (
        <Flex justify="center" align="center" h="80vh">
          <Loader />
        </Flex>
      )}

      {!loading && loanRequest && (
        <Box bg="#F8F9FC" p={["20px 10px", "20px 10px", "20px 10px 20px 30px"]}>
          <Flex justify="space-between">
            <Link to="/dashboard">
              <HStack
                color="#33343D"
                fontSize="11px"
                bg="#F8F1F6"
                p={["10px 5px", "10px"]}
                borderRadius="9px"
                fontWeight="500"
              >
                <BiChevronLeft color="#33343D" fontSize="17" />
                <Text>Back to requests</Text>
              </HStack>
            </Link>
            <RequestSLA loanRequestSLA={loanRequestSLA} />
            <HStack>
              {!isRejectedLoans && (
                <>
                  {/* {role !== "Finance Ops" && <RejectionModal id={id} />} */}
                  <RejectionModal id={id} />
                  {/* {role === "Sales Ops" && <></>} */}

                  {role === "Finance Ops" ? (
                    <CreditApprovalModal id={id} />
                  ) : role === "Finance Admin" ? (
                    <FinanceApproveLoanModal id={id} />
                  ) : (
                    <ApproveLoanModal id={id} />
                  )}
                </>
              )}

              {role === "Finance Admin" && (
                <>
                  {loanRequest?.finance_approval === "approved" &&
                    !loanRequest?.direct_debit_mandate_active && (
                      <Button
                        bg="#5B2E4F"
                        color="white"
                        opacity={"1px"}
                        fontSize="12px"
                        fontWeight="500"
                        fontFamily="satoshiBold"
                        p="13px 35px"
                        onClick={() => setIsOpen(true)}
                        isLoading={false}
                        loadingText="submitting"
                      >
                        SEND DIRECT DEBIT MANDATE
                      </Button>
                    )}
                </>
              )}
            </HStack>
          </Flex>

          <BorrowersInformation loanRequest={loanRequest} />
          <NokDisplay loanRequest={loanRequest} />
          <GuarantorInformation id={id} />
          <Documents loanRequest={loanRequest} />
          <BorrowerSignature loanRequest={loanRequest} />
          {loanRequest?.loan && (
            <FinanceApproval loanInfo={loanRequest?.loan} />
          )}
          <ApprovalWorkFlow loanRequest={loanRequest} />
          <LogLoanModal
            processing={processing}
            getLogs={getLogs}
            loanLogs={loanLogs}
            limit={limit}
            setLimit={setLimit}
          />

          {loanRequest?.config?.authorization_code && (
            <RepaymentConfig config={loanRequest?.config} />
          )}

          {loanRequest?.loan && (
            <InstantLoanOfferDetails loanRequest={loanRequest} />
          )}

          {loanRequest?.repaymentSchedule?.length > 0 && (
            <RepaymentSchedule loanRequest={loanRequest} />
          )}

          {loanRequest?.transactions?.length > 0 && (
            <TransactionLog loanRequest={loanRequest} />
          )}
        </Box>
      )}

      <ModalLayout
        cancelBtnText="BACK"
        title="Send Direct Debit Mandate"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        loading={processingMandate}
        backHandler={() => setIsOpen(false)}
        rejectHandler={sendMandateHandler}
      >
        <VStack w="100%" align="baseline" spacing={3}>
          <Box mt="20px" w="100%">
            <Text fontWeight="500" fontSize="13px" color="#33343D">
              Monthly Repayment Amount
            </Text>
            <Input
              type="number"
              mt="10px"
              value={formValues.monthly_repayment}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  monthly_repayment: e.currentTarget.value,
                })
              }
              className="form-input"
              placeholder="Enter Amount"
            />
          </Box>
        </VStack>
      </ModalLayout>
    </>
  );
};

export default RequestPage;
