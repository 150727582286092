import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Flex,
  HStack,
  Select,
} from "@chakra-ui/react";
import React from "react";
import ReactPaginate from "react-paginate";
import { BiChevronDown } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import Loader from "../Loader/loader";
import { formatMoney } from "../../services/utils/helpersFunc";

const HerVestTable = ({
  columns,
  data,
  meta,
  itemsPerPage,
  handlePageClick,
  setItemsPerPage,
  onClickHandler,
  loading,
}) => {
  return (
    <Box bg="white" mt="20px">
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th
                  textTransform="capitalize"
                  fontSize={["10px", "12px"]}
                  color="#262B40"
                  lineHeight="18px"
                  fontWeight="500"
                >
                  {column.name}
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody>
            {data?.map((item, i) => {
              return (
                <Tr
                  key={i}
                  cursor="pointer"
                  onClick={() => onClickHandler(item)}
                >
                  {columns.map((column) => (
                    <>
                      {column.key === "amount" ? (
                        <Td
                          fontSize={["10px", "12px"]}
                          color="#33343D"
                          lineHeight="18px"
                          fontWeight="500"
                          fontFamily="satoshiBold"
                        >
                          ₦{formatMoney(item[column.key])}
                        </Td>
                      ) : (
                        <Td
                          fontSize={["10px", "12px"]}
                          color="#33343D"
                          lineHeight="18px"
                          fontWeight="500"
                          fontFamily="satoshiBold"
                        >
                          {item[column.key]}
                        </Td>
                      )}
                    </>
                  ))}

                  <Td>
                    <BsThreeDotsVertical />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      {loading && <Loader />}

      {!loading && data?.length === 0 && (
        <p
          style={{
            textAlign: "center",
            color: "#666666",
            opacity: "0.85",
            fontWeight: "700",
            fontSize: "12px",
            marginTop: "40px",
          }}
        >
          NO RESULT FOUND
        </p>
      )}

      {data?.length > 0 && (
        <Flex
          justifyContent="space-between"
          flexWrap="wrap"
          bg="#FFFFFF"
          mt="5px"
          p="10px 20px"
        >
          <HStack>
            <Select
              icon={<BiChevronDown />}
              bg="#F8F8F8"
              border="none"
              borderRadius="5px"
              color="#666666"
              opacity="0.85"
              fontWeight="700"
              fontSize="12px"
              w="80px"
              h="30px"
              onChange={(e) => {
                setItemsPerPage(e.target.value);
              }}
            >
              <option value={10}>10</option>
              {data?.length > 20 && <option value={40}>20</option>}
              {data?.length > 30 && <option value={60}>30</option>}
              {data?.length > 40 && <option value={80}>40</option>}
              {data?.length > 50 && <option value={80}>50</option>}
            </Select>

            <Text
              color="#666666"
              opacity="0.85"
              fontWeight="700"
              fontSize="12px"
            >
              Showing 1-{itemsPerPage} of {data?.length} Enteries
            </Text>
          </HStack>

          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={(event) => handlePageClick(event)}
            pageRangeDisplayed={2}
            pageCount={meta?.totalPages}
            previousLabel="Prev"
            previousClassName="previousClassName"
            nextClassName="nextClassName"
            breakClassName="breakClassName"
            pageLinkClassName="pageLinkClassName"
            pageClassName="pageClassName"
            containerClassName="containerClassName"
            activeLinkClassName="activeLinkClassName"
            disabledClassName="disabledClassName"
            renderOnZeroPageCount={null}
          />
        </Flex>
      )}
    </Box>
  );
};

export default HerVestTable;
