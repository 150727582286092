import { Box, Text, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { formatMoney } from "../../services/utils/helpersFunc";

const FinanceApproval = ({ loanInfo }) => {
  return (
    <Box bg="#FFFFFF" mt="20px" borderRadius="10px">
      <Text
        color="#540A45"
        fontWeight="700"
        fontFamily="satoshiBolder"
        p="10px 20px"
        fontSize="14px"
      >
        Finance Approval Details
      </Text>
      <SimpleGrid
        spacing="20px"
        minChildWidth={["100px", "70px", "50px"]}
        p="20px"
        border="1px solid #E4E8F2"
      >
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            loan amount
          </Text>
          <Text
            fontWeight="700"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBolder"
          >
            {/* ₦{loanRequest.amount} */}
            {loanInfo?.proposed_amount &&
              `₦ ${formatMoney(loanInfo.proposed_amount)}`}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            tenure
          </Text>
          <Text
            fontWeight="700"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBolder"
          >
            {loanInfo.proposed_tenure} Months
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default FinanceApproval;
