import { Flex, Box, Button, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import authServices from "../../services/auth";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import {
  startRegistration,
  startAuthentication,
} from "@simplewebauthn/browser";
import { useNavigate } from "react-router-dom";

function LoginWithCode({ setLoginOption }) {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [is2faLogged, setIs2faLogged] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [challenge, setChallenge] = useState("");
  const [email, setEmail] = useState("");

  async function handleLoginRedirection(res) {
    localStorage.setItem("token", res.token);
    toast.success(`Welcome back ${res.admin.name}`);
    localStorage.setItem("role", res.admin.user_role);
    localStorage.setItem("name", res.admin.name);
    localStorage.setItem("userProfile", JSON.stringify(res.admin));
    setSubmitting(false);
    const lastPageVisited = sessionStorage.getItem("redirect_path");
    if (lastPageVisited) {
      navigate(`${lastPageVisited}`);
    } else {
      navigate("/dashboard");
    }
  }

  const loginHandler = async () => {
    if (!otp) return;

    if (otp.length < 6) {
      return toast.error("OTP must be 6 digits");
    }

    const payload = {
      email,
      token: otp,
    };
    setSubmitting(true);
    const res = await authServices.loginWith2FA(payload);
    setSubmitting(false);

    if (res) {
      localStorage.setItem("2FALogged", "true");
      return handleLoginRedirection(res);
    }
  };

  async function registerPasskey() {
    const payload = { email, accessFrom: "LOAN" };
    setSubmitting(true);
    const options = await authServices.authAdminPasskeyRegister(payload);
    const attestationResponse = await startRegistration(options);
    const verificationPayload = {
      credential: attestationResponse,
      email,
      accessFrom: "LOAN",
    };

    const res = await authServices.authAdminPasskeyVerifyPasskeyRegister(
      verificationPayload
    );
    setSubmitting(false);
    if (res) {
      localStorage.setItem("challenge", options.challenge);
      return handleLoginRedirection(res);
    }
  }

  async function loginPasskey() {
    const payload = {
      email,
    };
    setSubmitting(true);
    const options = await authServices.authAdminPasskeyLogin(payload);
    let attResp;
    try {
      attResp = await startAuthentication(options);
    } catch (error) {
      toast.error("We are unable to login with your passkey. Please try again");
      console.error(error);
      setSubmitting(false);
    }

    if (attResp) {
      const payload = {
        email,
        credential: attResp,
        challenge: options.challenge,
      };
      const res = await authServices.verifyAuthAdminPasskeyLogin(payload);
      if (res) {
        return handleLoginRedirection(res);
      }
      setSubmitting(false);
    }
  }

  useEffect(() => {
    const getAuthInfo = () => {
      const challenge = localStorage.getItem("challenge");
      if (challenge) setChallenge(challenge);
      const is2faLogged = localStorage.getItem("2FALogged");
      if (is2faLogged) setIs2faLogged(is2faLogged);
      const email = localStorage.getItem("email");
      if (email) setEmail(email);
    };
    getAuthInfo();
  }, []);

  return (
    <div>
      <Flex
        justify="center"
        alignItems="center"
        mt="30px"
        flexDirection={"column"}
      >
        {" "}
        <Box
          // w={["100%", "80%", "50%"]}
          boxShadow="0px 4px 12px 0px #D7D7D740"
          border="0.5px solid #EFEAED"
          borderRadius="10px"
          p="30px"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          mt={["60px"]}
        >
          <Text
            textAlign="center"
            fontSize="30px"
            color="#33343D"
            fontWeight="extrabold"
          >
            Welcome Back {localStorage.getItem("name")}
          </Text>
          <Text
            textAlign="center"
            fontSize="14px"
            color="#33343D"
            fontWeight="500"
            mt="20px"
          >
            Kindly open your Authenticator App to get the 6 digits OTP.
          </Text>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
            containerStyle={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0",
            }}
            placeholder="******"
            shouldAutoFocus={true}
            inputStyle={{
              width: "40px",
              height: "40px",
              fontSize: "20px",
              margin: "0 10px",
              textAlign: "center",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
          <Flex width={"100%"} alignItems={"center"} justify="center" mt="30px">
            <Button
              onClick={loginHandler}
              disabled={isSubmitting}
              isLoading={isSubmitting}
              loadingText="Please wait ..."
              bg="#5B2E4F"
              w={"100%"}
              borderRadius="6px"
              fontSize="12px"
              fontWeight="500"
              color="white"
              p="13px 52px"
              _hover={{
                color: "#5B2E4F",
                border: "1px solid #5B2E4F",
                bg: "white",
              }}
            >
              SIGN IN
            </Button>{" "}
          </Flex>

          {!challenge && is2faLogged && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mt="20px"
              width="100%"
            >
              <Button width={"100%"} onClick={registerPasskey} type="submit">
                {isSubmitting ? "Please wait... 🍉" : "Register Passkey"}
              </Button>
            </Box>
          )}
          {challenge && is2faLogged && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mt="20px"
              width="100%"
            >
              <Button width={"100%"} onClick={loginPasskey} type="submit">
                {isSubmitting ? "Please wait... 🍉" : "Login with Passkey"}
              </Button>
            </Box>
          )}
          <Text
            textAlign="center"
            fontSize="14px"
            color="#33343D"
            fontWeight="500"
            mt="20px"
          >
            Not {localStorage.getItem("name")}?{" "}
            <Button
              variant="link"
              onClick={() => {
                localStorage.clear();
                setLoginOption("password");
              }}
            >
              Log out
            </Button>
          </Text>
        </Box>
      </Flex>
    </div>
  );
}

export default LoginWithCode;
