import React, { useEffect, useState } from "react";
import { Box, Text, SimpleGrid } from "@chakra-ui/react";
import HerVestTable from "../components/Table/HervestTable";
import moment from "moment";
import { obscureText } from "../services/utils/helpersFunc";
import { getAllDirectDebits } from "../services/axios";
import ModalLayout from "../components/Layout/ModalLayout";
import HervestSearch from "../components/Search/HervestSearch";

const columns = [
  {
    name: "S/N",
    key: "id",
  },
  {
    name: "Account Name",
    key: "account_name",
  },
  {
    name: "DD Type",
    key: "card_type",
  },
  {
    name: "Bank",
    key: "bank",
  },
  {
    name: "Customer Email",
    key: "email",
  },
  {
    name: "Status",
    key: "status",
  },
  {
    name: "Activated on",
    key: "created_at",
  },
];

function DirectDebitPage() {
  const [directDebits, setDirectDebits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});

  let filters = {};
  const getDirectDebits = async () => {
    setLoading(true);
    const res = await getAllDirectDebits(filters);
    setLoading(false);
    if (res) {
      const mappedData = res.data.data.map((data, index) => {
        return {
          ...data,
          account_name: data.account_name,
          card_type: data.card_type,
          bank: data.bank,
          email: data?.user?.email,
          active: data.active,
          status: data.active ? "Active" : "Pending",
          created_at: moment(data.created_at).format("DD/MM/YYYY"),
        };
      });
      setDirectDebits(mappedData);
      setMeta(res?.data?.meta);
    }
    return;
  };

  useEffect(() => {
    getDirectDebits();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      bg="#F8F9FC"
      p={["20px 10px", "20px 10px", "20px 10px 20px 30px"]}
      h="100vh"
      w="100%"
    >
      <Text
        color="#5B2E4F"
        fontSize="18px"
        fontWeight="500"
        fontFamily="satoshiBold"
      >
        Direct Debit Mandates
      </Text>

      <HervestSearch
        searchHandler={(e) => {
          filters = {
            searchQuery: e.target.value,
          };
          getDirectDebits(filters);
        }}
      />

      <HerVestTable
        onClickHandler={(item) => {
          setSelectedDetails(item);
          setOpenDetails(true);
        }}
        meta={meta}
        data={directDebits}
        columns={columns}
        loading={loading}
      />

      {/* //DETAILS MODAL STARTS HERE  */}
      <ModalLayout
        setIsOpen={setOpenDetails}
        title="Direct Debit Details"
        hideFooter={true}
        isOpen={openDetails}
      >
        <Box bg="#FFFFFF" my="20px" borderRadius="10px">
          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                BANK
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {selectedDetails.bank || "-"}
              </Text>
            </Box>

            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                ACCOUNT NAME
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {selectedDetails?.account_name || "-"}
              </Text>
            </Box>
          </SimpleGrid>
          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                AUTHORIZATION CODE
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {obscureText(selectedDetails?.authorization_code) || "-"}
              </Text>
            </Box>

            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                SIGNATURE
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {obscureText(selectedDetails?.signature)}
              </Text>
            </Box>
          </SimpleGrid>
          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                AUTHORIZING BANK
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {selectedDetails?.bank}
              </Text>
            </Box>

            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                CHANNEL
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {selectedDetails?.channel}
              </Text>
            </Box>
          </SimpleGrid>
          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                DD EXPIRING MOTNTH
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {selectedDetails?.exp_month}
              </Text>
            </Box>

            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                DD EXPIRING YEAR
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {selectedDetails?.exp_year}
              </Text>
            </Box>
          </SimpleGrid>
          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                CUSTOMER EMAIL
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {selectedDetails?.user?.email}
              </Text>
            </Box>

            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                CUSTOMER PHONE NUMBER
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {selectedDetails?.user?.phone}
              </Text>
            </Box>
          </SimpleGrid>
        </Box>
      </ModalLayout>
    </Box>
  );
}

export default DirectDebitPage;
