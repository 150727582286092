import React, { useEffect, useState } from "react";
import { Box, Text, SimpleGrid } from "@chakra-ui/react";
import HerVestTable from "../components/Table/HervestTable";
import moment from "moment";
import { formatMoney } from "../services/utils/helpersFunc";
import ModalLayout from "../components/Layout/ModalLayout";
import HervestSearch from "../components/Search/HervestSearch";
import { getAllInstantLoanTransactions } from "../services/axios";

const columns = [
  {
    name: "S/N",
    key: "id",
  },
  {
    name: "Amount",
    key: "amount",
  },
  {
    name: "Reference",
    key: "reference",
  },
  {
    name: "Loan Request",
    key: "request_id",
  },
  {
    name: "Type",
    key: "type",
  },
  {
    name: "Tag",
    key: "tag",
  },
  {
    name: "Payment Date",
    key: "created_at",
  },
];

function IntsantLoanTransactions() {
  const [allTransactions, setallTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  let filters = {
    page: 1,
    pageLimit: 50,
  };

  const getallTransactions = async () => {
    setLoading(true);
    const res = await getAllInstantLoanTransactions(filters);
    setLoading(false);
    if (res) {
      const mappedData = res.data.data.map((data, index) => {
        return {
          ...data,
          created_at: moment(data.created_at).format("DD/MM/YYYY"),
        };
      });
      setallTransactions(mappedData);
      setMeta(res?.data?.meta);
    }
    return;
  };

  const handlePageClick = (page) => {
    filters = {
      ...filters,
      page: page.selected + 1,
      pageLimit: 50,
    };
    getallTransactions();
  };

  useEffect(() => {
    getallTransactions();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      bg="#F8F9FC"
      p={["20px 10px", "20px 10px", "20px 10px 20px 30px"]}
      h="100vh"
      w="100%"
    >
      <Text
        color="#5B2E4F"
        fontSize="18px"
        fontWeight="500"
        fontFamily="satoshiBold"
      >
        Instant Loan Transactions
      </Text>

      <HervestSearch
        searchHandler={(e) => {
          filters = {
            searchQuery: e.target.value,
          };
          getallTransactions(filters);
        }}
      />

      <HerVestTable
        onClickHandler={(item) => {
          setSelectedDetails(item);
          setOpenDetails(true);
        }}
        meta={meta}
        data={allTransactions}
        columns={columns}
        loading={loading}
        handlePageClick={(value) => handlePageClick(value)}
      />

      {/* //DETAILS MODAL STARTS HERE  */}
      <ModalLayout
        setIsOpen={setOpenDetails}
        title="Transaction Details"
        hideFooter={true}
        isOpen={openDetails}
      >
        <Box bg="#FFFFFF" my="20px" borderRadius="10px">
          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                AMOUNT
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                ₦{formatMoney(selectedDetails.amount) || "-"}
              </Text>
            </Box>

            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                TRANSACTION TAG
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {selectedDetails?.tag || "-"}
              </Text>
            </Box>
          </SimpleGrid>
          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                BALANCE BEFORE
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                ₦{formatMoney(selectedDetails?.balance_before) || "-"}
              </Text>
            </Box>

            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                BALANCE AFTER
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                ₦{formatMoney(selectedDetails?.balance_after) || "-"}
              </Text>
            </Box>
          </SimpleGrid>
          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                NARRATION
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {selectedDetails?.narration}
              </Text>
            </Box>
          </SimpleGrid>
          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                REFERENCE
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {selectedDetails?.reference}
              </Text>
            </Box>

            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                TRASACTION TYPE
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {selectedDetails?.type}
              </Text>
            </Box>
          </SimpleGrid>
          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                TRANSACTION DATE
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {selectedDetails?.created_at}
              </Text>
            </Box>
          </SimpleGrid>
        </Box>
      </ModalLayout>
    </Box>
  );
}

export default IntsantLoanTransactions;
