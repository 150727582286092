import { Box, Text, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { formatMoney } from "../../services/utils/helpersFunc";
// import moment from "moment";

const InstantLoanOfferDetails = ({ loanRequest }) => {
  return (
    <Box bg="#FFFFFF" mt="20px" borderRadius="10px">
      <Text
        color="#540A45"
        fontWeight="700"
        fontFamily="satoshiBolder"
        p="10px 20px"
        fontSize="14px"
      >
        Loan Record Information
      </Text>
      <SimpleGrid
        spacing="20px"
        minChildWidth={["100px", "70px", "50px"]}
        p="20px"
        border="1px solid #E4E8F2"
      >
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            LOAN AMOUNT
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            NGN{" "}
            {formatMoney(
              loanRequest?.loan?.amount || loanRequest?.loan?.proposed_amount
            ) || "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            OPENING BALANCE
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            NGN {formatMoney(loanRequest?.loan?.opening_balance) || "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            CURRENT BALANCE
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            NGN {formatMoney(loanRequest?.loan?.current_balance)}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            CLOSING BALANCE
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            NGN {formatMoney(loanRequest?.loan?.closing_balance)}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            INTEREST RATE
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest?.loan?.interest_rate ||
              loanRequest?.loan?.proposed_monthly_interest ||
              "-"}{" "}
            %
          </Text>
        </Box>
      </SimpleGrid>
      <SimpleGrid
        spacing="20px"
        minChildWidth={["100px", "70px", "50px"]}
        p="20px"
        border="1px solid #E4E8F2"
      >
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            PROCESSING FEE
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            NGN {formatMoney(loanRequest?.loan?.processing_fee)}
          </Text>
        </Box>

        {loanRequest?.loan?.total_interest && (
          <Box>
            <Text
              opacity="0.81px"
              fontWeight="500"
              fontSize="10px"
              color="#666666"
              lineHeight="18px"
            >
              TOTAL INTEREST
            </Text>
            <Text
              fontWeight="500"
              fontSize="14px"
              color="#33343D"
              lineHeight="18px"
              fontFamily="satoshiBold"
            >
              NGN {formatMoney(loanRequest?.loan?.total_interest)}
            </Text>
          </Box>
        )}

        {loanRequest?.loan?.total_monthly_repayment && (
          <Box>
            <Text
              opacity="0.81px"
              fontWeight="500"
              fontSize="10px"
              color="#666666"
              lineHeight="18px"
              textTransform="uppercase"
            >
              MONTHLY REPAYMENT
            </Text>
            <Text
              fontWeight="500"
              fontSize="14px"
              color="#33343D"
              lineHeight="18px"
              fontFamily="satoshiBold"
            >
              NGN {formatMoney(loanRequest?.loan?.total_monthly_repayment)}
            </Text>
          </Box>
        )}

        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            TOTAL REPAYMENT
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            NGN {formatMoney(loanRequest?.loan?.total_repayment)}
          </Text>
        </Box>

        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            TENURE
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest?.loan?.tenure || loanRequest?.loan?.proposed_tenure}{" "}
            Months
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default InstantLoanOfferDetails;
