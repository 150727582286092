import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import MenuSidebar from "./components/Sidebar/MenuSidebar";
import Dashboard from "./Pages/Dashboard";
import RequestPage from "./Pages/RequestPage";
import ApprovalPage from "./Pages/ApprovalPage";
import Signin from "./Pages/Signin";
import InstantLoan from "./Pages/InstantLoan";
import InstantLoanDetails from "./Pages/instantLoanDetail";
import Rejected from "./Pages/Rejected";
import PendingDisbursement from "./Pages/PendingDisbursement";
import PendingDisbursementDetails from "./Pages/PendingDisbursementDetails";
import DirectDebitPage from "./Pages/DirectDebitPage";
import IntsantLoanTransactions from "./Pages/IntsantLoanTransactions";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route exact={true} path="/" element={<Signin />} />
          <Route exact={true} element={<MenuSidebar />}>
            <Route
              exact={true}
              index
              path="/dashboard"
              element={<Dashboard />}
            />
            <Route exact={true} index path="/rejected" element={<Rejected />} />
            <Route
              exact={true}
              index
              path="requests/:id"
              element={<RequestPage />}
            />
            <Route
              exact={true}
              index
              path="approvals"
              element={<ApprovalPage />}
            />
            <Route
              exact={true}
              index
              path="instant-loan"
              element={<InstantLoan />}
            />

            <Route
              exact={true}
              index
              path="transactions"
              element={<IntsantLoanTransactions />}
            />

            <Route
              exact={true}
              index
              path="instant-loan/:id"
              element={<InstantLoanDetails />}
            />

            <Route
              exact={true}
              index
              path="pending-disbursement"
              element={<PendingDisbursement />}
            />

            <Route
              exact={true}
              index
              path="pending-disbursement/:id"
              element={<PendingDisbursementDetails />}
            />

            <Route
              exact={true}
              index
              path="/dashboard/direct-debits"
              element={<DirectDebitPage />}
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;
