import { Flex, Box, Button, Text, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import authServices from "../../services/auth";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

function ValidateAuthenticator({ validateDetails }) {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  const loginHandler = async () => {
    const payload = {
      email: localStorage.getItem("email"),
      token: otp,
    };
    setSubmitting(true);
    const res = await authServices.loginWith2FA(payload);
    setSubmitting(false);

    if (res) {
      localStorage.setItem("token", res.token);
      toast.success(`Welcome back ${res.admin.name}`);
      const lastPageVisited = sessionStorage.getItem("redirect_path");
      if (lastPageVisited) {
        navigate(`${lastPageVisited}`);
      } else {
        navigate("/dashboard");
      }

      localStorage.setItem("role", res.admin.user_role);
      localStorage.setItem("name", res.admin.name);
      localStorage.setItem("userProfile", JSON.stringify(res.admin));
      setSubmitting(false);
    }
  };

  return (
    <Flex
      justify="center"
      alignItems="center"
      mt="30px"
      flexDirection={"column"}
    >
      <Box
        // w={["100%", "80%", "50%"]}
        boxShadow="0px 4px 12px 0px #D7D7D740"
        border="0.5px solid #EFEAED"
        borderRadius="10px"
        p="30px"
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image
          w="300px"
          h="300px"
          justifyContent={"center"}
          alignItems={"center"}
          src={validateDetails?.qrCode}
          alt="logo"
        />
        <Text
          textAlign="center"
          fontSize="14px"
          color="#33343D"
          fontWeight="500"
          mt="20px"
        >
          Kindly Scan the QR code with your Authenticator App to get the OTP.{" "}
          <br />
          Once scanned, enter the OTP below.
        </Text>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          containerStyle={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0",
          }}
          shouldAutoFocus={true}
          placeholder="******"
          inputStyle={{
            width: "40px",
            height: "40px",
            fontSize: "20px",
            margin: "0 10px",
            textAlign: "center",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
        <Flex alignItems={"center"} justify="center" mt="30px">
          <Button
            onClick={loginHandler}
            disabled={isSubmitting}
            isLoading={isSubmitting}
            loadingText="Please wait ..."
            bg="#5B2E4F"
            w={"100%"}
            borderRadius="6px"
            fontSize="12px"
            fontWeight="500"
            color="white"
            p="13px 52px"
            _hover={{
              color: "#5B2E4F",
              border: "1px solid #5B2E4F",
              bg: "white",
            }}
          >
            PROCEED
          </Button>{" "}
        </Flex>
      </Box>
    </Flex>
  );
}

export default ValidateAuthenticator;
