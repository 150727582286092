import { Box, Text, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { formatMoney } from "../../services/utils/helpersFunc";

const CreditRecordScoring = ({ loanRequest }) => {
  return (
    <Box bg="#FFFFFF" mt="20px" borderRadius="10px">
      <Text
        color="#540A45"
        fontWeight="700"
        fontFamily="satoshiBolder"
        p="10px 20px"
        fontSize="14px"
      >
        Credit's Record
      </Text>
      <SimpleGrid
        spacing="20px"
        minChildWidth={["100px", "70px", "50px"]}
        p="20px"
        border="1px solid #E4E8F2"
      >
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            CLASS OF EMPLOYMENT STORE
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest?.class_of_employment_score || "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            CREDIT DOCUMENTS
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest?.credit_documents && (
              <a
                href={loanRequest?.credit_documents}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Text
                  color="#5B2E4F"
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  View Credit Report
                </Text>
              </a>
            )}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            CREDIT HISTORY SCORE
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest?.credit_history_score || "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            CREDIT RECOMMENDATION STATUS
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest?.credit_recommendation_status || "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            QUALITY OF EMPLOYMENT SCORE
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest?.quality_of_employment_score || "-"}
          </Text>
        </Box>
      </SimpleGrid>
      <SimpleGrid
        spacing="20px"
        minChildWidth={["100px", "70px", "50px"]}
        p="20px"
        border="1px solid #E4E8F2"
      >
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            CREDIT RECOMMENDED AMOUNT
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {`₦ ${formatMoney(
              Number(loanRequest.credit_recommended_amount)
            )}` || "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            DEBIT INCOME SCORE
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest?.debit_income_score || "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            TYPE OF EMPLOYMENT SCORE
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest?.type_of_employer_score || "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            YEARS OF EMPLOYMENT SCORE
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest?.years_of_employment_score || "-"}
          </Text>
        </Box>

        {loanRequest?.credit_recommendation_reason && (
          <Box>
            <Text
              opacity="0.81px"
              fontWeight="500"
              fontSize="10px"
              color="#666666"
              lineHeight="18px"
              textTransform="uppercase"
            >
              CREDIT RECOMMENTATION REASON
            </Text>
            <Text
              fontWeight="500"
              fontSize="14px"
              color="#33343D"
              lineHeight="18px"
              fontFamily="satoshiBold"
            >
              {loanRequest?.credit_recommendation_reason || "-"}
            </Text>
          </Box>
        )}
      </SimpleGrid>
    </Box>
  );
};

export default CreditRecordScoring;
