import React, { Fragment } from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import moment from "moment";
import { formatMoney } from "../../services/utils/helpersFunc";
import { FaRegCalendarDays } from "react-icons/fa6";
import { ImCheckmark } from "react-icons/im";
const RepaymentSchedule = ({ loanRequest }) => {
  return (
    <Box bg="#FFFFFF" mt="20px" borderRadius="10px" p="10px 20px">
      <Text
        color="#540A45"
        fontWeight="700"
        fontFamily="satoshiBolder"
        fontSize="14px"
      >
        Repayment Schedules
      </Text>
      {loanRequest?.repaymentSchedule?.map((schedule, index) => (
        <Fragment key={index}>
          <Flex marginY="5">
            <FaRegCalendarDays size={30} color="#540A45" />

            <Box fontSize="14px" paddingLeft="10px">
              <Text>
                {" "}
                Date:{" "}
                {moment(schedule?.date, "YYYY-MM-DD").format("DD MMMM YYYY")}
              </Text>
              <Text>Amount Due: {formatMoney(schedule?.amount_due)}</Text>
              <Text
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
                color={schedule?.status === "Paid" ? "#1BB100" : "#FF0000"}
              >
                Status: {schedule?.status}{" "}
                {schedule?.status === "Paid" && <ImCheckmark color="#1BB100" />}
              </Text>
            </Box>
          </Flex>
        </Fragment>
      ))}

      {loanRequest?.schedules?.length === 0 && (
        <Text marginY={5}>No schedule log available</Text>
      )}
    </Box>
  );
};

export default RepaymentSchedule;
