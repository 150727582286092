import React from "react";
import { Box, Text, Flex, SimpleGrid } from "@chakra-ui/react";
import { ImCheckmark } from "react-icons/im";
import { obscureText } from "../../services/utils/helpersFunc";
import moment from "moment";
const RepaymentConfig = ({ config }) => {
  return (
    <Box bg="#FFFFFF" mt="20px" borderRadius="10px">
      <Text
        color="#540A45"
        fontWeight="700"
        fontFamily="satoshiBolder"
        p="10px 20px"
        fontSize="14px"
      >
        Direct Debit Mandate
      </Text>
      <SimpleGrid
        spacing="20px"
        minChildWidth={["100px", "70px", "50px"]}
        p="20px"
        border="1px solid #E4E8F2"
      >
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            ACCOUNT NAME
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {config?.repayment_bank_account_no}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            BANK NAME
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {config?.repayment_bank_name}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            STATUS
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {config?.status === "active" ? (
              <Flex>
                <ImCheckmark color="#00B87C" />
                Active
              </Flex>
            ) : (
              <Flex>
                <ImCheckmark color="#FF0000" />
                Inactive
              </Flex>
            )}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            AUTHORIZATION CODE
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {obscureText(config?.authorization_code || "-")}
          </Text>
        </Box>

        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            ACTIVATED ON
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {moment(config?.activated_on).format("MMMM DD, YYYY")}
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default RepaymentConfig;
